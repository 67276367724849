import React from 'react';
import { Link } from 'react-router-dom';
import textsService from 'services/textsService';
// import logo from 'assets_vacanted/Vacanted_LOGO_header.png';
import instagram from 'assets_vacanted/instagram.svg';
import facebook from 'assets_vacanted/facebook.svg';
import linkedin from 'assets_vacanted/linkedin.svg';
import twitter from 'assets_vacanted/twitter.svg';
// import logo from 'assets_vacanted/Vacanted_LOGO_header.png';
// import insta from 'assets_vacanted/insta.png';
import styles from './styles.module.scss';

function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className={`${styles['footer-container']} dark-footer skin-dark-footer`}>
      {/* <div className="ht-80" /> */}
      <div className={styles.footer_delimiter}>
        <div>
          <div className={styles.container_footer}>
            <div className={styles.footer_wrapper}>

              <div className="">
                <div className="footer-widget">
                  <p className={styles.brand_cta}>
                    {textsService.getLocalizedText(textsService.TEXT_KEYS.FOOTER_TEXT)}
                  </p>
                  <ul className={styles.footer_bottom_social}>
                    <li><a href="https://www.facebook.com/vacanted.latam" target="_blank" rel="noreferrer"><img src={facebook} alt="facebook icon" /></a></li>
                    <li><a href="https://www.instagram.com/vacanted.latam/" target="_blank" rel="noreferrer"><img src={instagram} alt="instagram icon" /></a></li>
                    <li><a href="https://linkedin.com/company/vacanted/" target="_blank" rel="noreferrer"><img src={linkedin} alt="linkedin icon" /></a></li>
                    <li><a href="https://x.com/vacanted_latam" target="_blank" rel="noreferrer"><img src={twitter} alt="X icon" /></a></li>
                  </ul>
                </div>
              </div>

              <div className={styles.sessions_wrapper}>
                {/* <div className="">
                  <div className={styles.footer_menu}>
                    <h4 className="widget-title">Secciones</h4>
                    <ul className="footer-list">
                      <Link to="/vacantest">Test de Compatibilidad</Link>
                      <br />
                      <Link to="/search">Búsqueda Inteligente</Link>
                      <br />
                      <Link to="/como-funciona">Cómo funciona</Link>
                      <br />
                      <Link to="/faq">Preguntas frecuentes</Link>
                      <br />
                      // <Link to="/contact">Contacto</Link>
                      <a href="mailto:info@vacanted.com">Contacto</a>
                      <br />
                    </ul>
                  </div>
                </div> */}

                {/* <div className="">
                  <div className={styles.footer_blog}>
                    <h4 className="widget-title">Comunidad</h4>
                    <ul className="footer-menu">
                      <Link to="/blog">Blog</Link>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className={styles.text_center}>
            <span className={styles.footer_line}>
              {`© ${year} VACANTED™`}
            </span>
            <span className={styles.bullet_points}>. </span>
            <span className={styles.footer_line}>
              <Link to="/terms-and-conditions">Términos y Condiciones </Link>
            </span>
            <span className={styles.bullet_points}>. </span>
            <span className={styles.footer_line}>
              <Link to="/privacy-policies">Política de Privacidad</Link>
            </span>
            <span className={styles.bullet_points}>. </span>
            <span className={styles.footer_line}>
              <a href="mailto:info@vacanted.com">info@vacanted.com</a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
